import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import styled, { keyframes, createGlobalStyle  } from 'styled-components';
import Fondo from '../../assets/img/SYK/Fondo1.png'

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&family=Great+Vibes&display=swap');
`;

const updown = keyframes`
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
`;

const BannerSection = styled.section`
margin-top: 0;
padding: 260px 0 80px 0;
background-image: url(${Fondo});
background-size: cover; /* Hace que la imagen de fondo cubra todo el contenedor */
background-position: center; /* Centra la imagen de fondo */
background-color: #919682;

@media (max-width: 768px) {
  padding: 150px 0 80px 0;
  background-size: contain; /* Ajusta la imagen para dispositivos medianos */
  background-position: center top; /* Ajusta la posición para dispositivos medianos */
}

@media (max-width: 480px) {
  padding: 100px 0 50px 0;
  background-size: contain; /* Ajusta la imagen para dispositivos pequeños */
  background-position: center top; /* Ajusta la posición para dispositivos pequeños */
}

  .tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    background: linear-gradient(90.21deg, rgba(144, 123, 1187, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 20px;
    margin-bottom: 16px;
    display: inline-block;

    @media (max-width: 768px) {
        font-size: 18px;
      }
    
      @media (max-width: 480px) {
        font-size: 16px;
      }
  }

  h1 {
    font-size: 75px;
    font-weight: 400;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
    text-align: center;
    font-family: "RalewayIt";

    @media (max-width: 768px) {
        font-size: 50px;
      }
    
      @media (max-width: 480px) {
        font-size: 35px;
      }
  }

  p {
    color: #cac9c9;
    font-size: 28px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 96%;

    @media (max-width: 768px) {
        font-size: 24px;
      }
    
      @media (max-width: 480px) {
        font-size: 20px;
      }
  }

  img {
    animation: ${updown} 3s linear infinite;
    max-width: 100%;
  }

  .txt-rotate > .wrap {
    border-right: 0.08em solid #666;
    font-family: 'Amiri';
  }
  
.container {
    text-align: center;
  }
  
  .text-block {
    display: inline-block;
    position: relative;
    white-space: nowrap; /* Prevents line breaks */
  }
  
  .text-block span {
    display: inline-block;
    position: relative;
    margin: 0 10px;
  }
  
  .text-small {
    font-family: Amiri;
    font-size: 20px;
    top: -60px; /* Adjust the vertical position */
  }
  
  .text-medium {
    font-family: "Great Vibes", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 100px;
    top: 0px; /* Adjust the vertical position */
  }
  
  .text-large {
    font-family: Amiri;
    font-size: 16px;
    top: 20px; /* Adjust the vertical position */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .text-medium {
      font-size: 72px;
    }
  
    .text-small {
      top: -30px;
    }
  
    .text-large {
      top: 30px;
    }
    .fechalugar {
      font-size: 16px
    }
  }
  
  @media (max-width: 480px) {
    .text-medium {
      font-size: 56px;
    }
  
    .text-small,
    .text-large {
      font-size: 14px;
    }
  
    .text-small {
      top: -20px;
    }
  
    .text-large {
      top: 20px;
    }
    .fechalugar {
      font-size: 8px
    }
  }
  .padding5 {
    padding-top: 5px;
  }
`;


export const BannerSYK = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "KEVIN & SUSANA" ];
  const period = 1000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text, delta])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting 
        ? fullText.substring(0, text.length - 1) 
        : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
    //   setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
    //   setIndex(1);
      setDelta(500);
    } 
    // else {
    //   setIndex(prevIndex => prevIndex + 1);
    // }
  }

  return (
    <BannerSection id="home">
      <GlobalStyle />
      <Container>
        <Row className="container">
            <div class="text-block">
                <span class="text-small">LA</span>
                <span class="text-medium">Boda</span>
                <span class="text-large">de</span>
            </div>
        </Row>
        <Row>
          <Col className="padding5">
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h1>{``} <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "KEVIN & SUSANA" ]'><span className="wrap">{text}</span></span></h1>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
        <Row>
          <Col>
              <div>
                <span className="fechalugar">29 DE AGOSTO, 2024 </span>
                <span className="fechalugar">MONTERREY, NUEVO LEÓN</span>
              </div>
          </Col>
        </Row>
      </Container>
    </BannerSection>
  )
}
import './ExaPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "../../components/Example/NavBar";
import styled from 'styled-components';
import { Banner } from '../../components/Example/Banner';
import { Mensaje } from '../../components/Example/Mensaje';
import { Fecha } from '../../components/Example/Fecha';
import { CountDown } from '../../components/Example/CountDown';
import { Fotos } from '../../components/Example/Fotos';
import { MesaRegalo } from '../../components/Example/MesaRegalo';
import { Footer } from '../../components/Example/Footer';


const StyledMain = styled.section`
  background-color: #FFFFF0 !important;
  color: #93B8AF !important;
`;

function ExaPage() {
  return (
    <StyledMain className="App">
      <NavBar />
      <Banner />
      <Mensaje />
      <Fecha />
      <CountDown />
      <Fotos />
      <MesaRegalo />
      <Footer />
    </StyledMain>
  );
}

export default ExaPage;

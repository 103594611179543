import React from 'react';
import { Col } from "react-bootstrap";
import styled from 'styled-components';

const ProjImgBx = styled.div`
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;

  &::before {
    content: "";
    /* background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%); */
    opacity: 0.85;
    position: absolute;
    width: 100%;
    height: 0;
    transition: 0.4s ease-in-out;
  }

  &:hover::before {
    height: 100%;
  }
`;

const ProjTxtX = styled.div`
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;

  ${ProjImgBx}:hover & {
    top: 50%;
    opacity: 1;
  }
`;

export const Foto = ({imgUrl }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <ProjImgBx>
        <img src={imgUrl}/>
      </ProjImgBx>
    </Col>
  );
};

import 'react-multi-carousel/lib/styles.css';
import Table from 'react-bootstrap/Table';
import { Check } from 'react-bootstrap-icons'
import { X } from 'react-bootstrap-icons'
import styled, { keyframes } from 'styled-components';

const PaqueteSection = styled.section`
padding: 0 0 15px 0;
position: relative;

  .skill-bx {
    background: #fff;
    /* border-radius: 64px; */
    text-align: center;
    padding: 70px 150px;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
h2 {
      font-size: 45px;
      font-weight: 700;
    color: #84c6cc;
    margin-bottom: 40px;
  }
p {
      color: #000;
      font-size: 18px;
      letter-spacing: 0.8px;
      line-height: 1.5em;
      margin-bottom: 20px;
      /* margin: 14px 0 75px 0; */
  }
  
th {
    font-size: 22px;
  }
  
td {
    font-size: 18px;
  }
  
.tabla-responsiva {
    overflow-x: auto;
  }
  
  .tabla-responsiva table {
    width: 100%;
    border-collapse: collapse;
    text
  }
  
  .tabla-responsiva th,
  .tabla-responsiva td {
    padding: 8px;
    text-align: center;

  }
  
  @media (max-width: 760px) {
    .tabla-responsiva th,
    .tabla-responsiva td {
      font-size: 14px;
      padding: 7px; /* Eliminar padding en pantallas pequeñas */
    }

    .skill-bx {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 15px;
      padding: 20px; /* Ajustar padding si es necesario */
    }
`;

export const Paquetes = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <PaqueteSection className="skill" id="skills">
        <div className="container">
          <div className="skill-bx wow zoomIn tabla-responsiva">
              <h2>Paquetes</h2>
              <Table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Paquete Pincel</th>
                    <th>Paquete Acuarelas</th>
                    {/* <th>Paquete lienzo</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Landing page</td>
                    <td><Check color="black"/></td>
                    <td><Check color="black"/></td>
                  </tr>
                  <tr>
                    <td>Logo</td>
                    <td><Check color="black"/></td>
                    <td><Check color="black"/></td>
                  </tr>
                  <tr>
                    <td>Agradecimiento</td>
                    <td><Check color="black"/></td>
                    <td><Check color="black"/></td>
                  </tr>
                  <tr>
                    <td>Ubicación</td>
                    <td><Check color="black"/></td>
                    <td><Check color="black"/></td>
                  </tr>
                  <tr>
                    <td>Cuenta regresiva</td>
                    <td><Check color="black"/></td>
                    <td><Check color="black"/></td>
                  </tr>
                  <tr>
                    <td>Galería de fotos</td>
                    <td><Check color="black"/></td>
                    <td><Check color="black"/></td>
                  </tr>
                  <tr>
                    <td>Mesa de Regalos</td>
                    <td><Check color="black"/></td>
                    <td><Check color="black"/></td>
                  </tr>
                  <tr>
                    <td>Mapa</td>
                    <td><Check color="black"/></td>
                    <td><Check color="black"/></td>
                  </tr>
                  <tr>
                    <td>Dresscode</td>
                    <td><Check color="black"/></td>
                    <td><Check color="black"/></td>
                  </tr>
                  <tr>
                    <td>Vigencia por 2 meses</td>
                    <td><Check color="black"/></td>
                    <td><Check color="black"/></td>
                  </tr>
                  <tr>
                    <td>Envios ilimitados</td>
                    <td><Check color="black"/></td>
                    <td><Check color="black"/></td>
                  </tr>
                  <tr>
                    <td>Confirmación de asistencia</td>
                    <td><Check color="black"/></td>
                    <td><Check color="black"/></td>
                  </tr>
                  <tr>
                    <td>RSVP personalizados</td>
                    <td><X color="red"/></td>
                    <td><Check color="black"/></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>$1000</td>
                    <td>$1700</td>
                  </tr>
                </tbody>
              </Table>
          </div>
        </div>
    </PaqueteSection>
  )
}

import React from 'react';
import styled from 'styled-components';
import Fondo from '../../assets/img/SYK/Fondo2.png'

const StyledMap = styled.section`
@import url('https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&family=Gilda+Display&family=Great+Vibes&display=swap');
font-family: "Gilda Display", serif;
width: 100%;
text-align: center;
background-image: url(${Fondo});
background-size: cover; /* Hace que la imagen de fondo cubra todo el contenedor */
background-position: center; /* Centra la imagen de fondo */
background-color: #919682;

@media (max-width: 768px) {
  padding: 150px 0 80px 0;
  background-size: contain; /* Ajusta la imagen para dispositivos medianos */
  background-position: center top; /* Ajusta la posición para dispositivos medianos */
}

@media (max-width: 480px) {
  padding: 100px 0 50px 0;
  background-size: contain; /* Ajusta la imagen para dispositivos pequeños */
  background-position: center top; /* Ajusta la posición para dispositivos pequeños */
}

.tituloMesa {
  font-size: 45px;
  font-weight: 400;
  text-align: center;
  color: #fff;
}

.container {
  display: grid;
  place-items: center; /* Centra tanto horizontal como verticalmente */
  height: 100vh;       /* Asegura que el contenedor ocupe toda la altura de la pantalla */
}

.map-container {
  position: relative;
  padding-bottom: 56.25%; /* Relación de aspecto 16:9 */
  height: 0;
  overflow: hidden;
  width: 70%;
  max-width: 100%;
  background: #e0e0e0;
}
  
.map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

@media (max-width: 768px) {
  .tituloMesa {
    font-size: 36px;
  }

  .map-container {
    width: 90%; /* Adjust width for smaller screens */
  }
}

@media (max-width: 480px) {
  .tituloMesa {
    font-size: 24px;
  }

  .map-container {
    width: 100%; /* Full width for very small screens */
  }
}
`;

export const MapSYK = () => {
  return (
    <StyledMap id='mapa'>
      <h1 className='tituloMesa'>Mapa</h1>
      <div className='container'>
      <div className='map-container'>
        <iframe
          title="Mapa"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3595.6177707981838!2d-100.30685302554082!3d25.683962411814782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662958409d604d1%3A0x816c206d0949c8c7!2sJardin%20Ambar%20Monterrey!5e0!3m2!1sen!2smx!4v1716259250611!5m2!1sen!2smx"
          allowFullScreen
        ></iframe>
      </div>
      </div>
    </StyledMap>
  )
}
import React from 'react';
import styled from 'styled-components';
import Fondo from '../../assets/img/SYK/Fondo2.png'

const StyledParentsSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px 0;
  background-image: url(${Fondo});
  background-size: cover; /* Hace que la imagen de fondo cubra todo el contenedor */
  background-position: center; /* Centra la imagen de fondo */
  background-color: #919682;
  
  @media (max-width: 768px) {
    padding: 150px 0 80px 0;
    background-size: contain; /* Ajusta la imagen para dispositivos medianos */
    background-position: center top; /* Ajusta la posición para dispositivos medianos */
  }
  
  @media (max-width: 480px) {
    padding: 100px 0 50px 0;
    background-size: contain; /* Ajusta la imagen para dispositivos pequeños */
    background-position: center top; /* Ajusta la posición para dispositivos pequeños */
  }

  .title {
    font-size: 42px;
    font-weight: 400;
    color: #13322C;
    margin-bottom: 30px;
    font-family: "Great Vibes", cursive;
  }

  .parents {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 800px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .column {
    flex: 1;
    padding: 0 20px;

    .parent-title {
      font-size: 24px;
      font-weight: 400;
      color: #fff;
      margin-bottom: 20px;
      font-family: 'Gilda Display', serif;
    }

    .parent-names {
      font-size: 38px;
      font-weight: 300;
      color: #fff;
      margin-bottom: 10px;
      font-family: 'Amiri', serif;
    }
  }
`;

const PadresSYK = () => {
  return (
    <StyledParentsSection>
      <div className="title">Con la bendición de Dios y de nuestros padres</div>
      <div className="parents">
        <div className="column">
          <div className="parent-title">Padres de la Novia</div>
          <div className="parent-names">
            <p>Zulma Noriega</p>
            <p>Fernando Tapia</p>
          </div>
        </div>
        <div className="column">
          <div className="parent-title">Padres del Novio</div>
          <div className="parent-names">
            <p>Lorena Fuentes</p>
            <p>Juan Joachin</p>
          </div>
        </div>
      </div>
    </StyledParentsSection>
  );
};

export default PadresSYK;

import { Container, Row, Col } from "react-bootstrap";
import logo from "../../assets/img/invitarte.png";
import whatsapp from "../../assets/img/whatsapp.svg";
import facebook from "../../assets/img/facebook.svg";
import instagram from "../../assets/img/instagram.svg";
import styled, { keyframes } from 'styled-components';

const FooterSection = styled.section`
padding: 0 0 50px 0;
background-color: #4b8d8d;
background-position: center center;
background-size: cover;
background-repeat: no-repeat;

img {
    width: 26%;
  }
p {
    font-weight: 400;
    font-size: 14px;
    color: #B8B8B8;
    letter-spacing: 0.5px;
    margin-top: 20px;
  }
  .social-icon {
    display: inline-block;
    margin-left: 14px;
    color: white;

    a {
      width: 42px;
      height: 42px;
      background: rgba(217, 217, 217, 0.1);
      display: inline-flex;
      border-radius: 50%;
      margin-right: 6px;
      align-items: center;
      justify-content: center;
      line-height: 1;
      border: 1px solid rgba(255, 255, 255, 0.5);
      position: relative;

      &::before {
        content: "";
        width: 42px;
        height: 42px;
        position: absolute;
        background-color: #ffffff;
        border-radius: 50%;
        transform: scale(0);
        transition: 0.3s ease-in-out;
      }

      &:hover::before {
        transform: scale(1);
      }

      img {
        width: 40%;
        z-index: 1;
        transition: 0.3s ease-in-out;

        &:hover {
          filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
        }
      }
    }
  }
`;
export const Footer = () => {
  return (
    <FooterSection className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.facebook.com/profile.php?id=61551920145968"><img src={facebook} alt="Icon" /></a>
              <a href="https://www.instagram.com/invit.artedigital"><img src={instagram} alt="Icon" /></a>
              <a href="https://wa.me/528117652567?text=Quiero%20informes%20de%20sus%20invitaciones"><img src={whatsapp} alt="Icon" /></a>
            </div>
          </Col>
        </Row>
      </Container>
    </FooterSection>
  )
}

import React from 'react';
import HomePage from './pages/Home/HomePage';
// import Example from './pages/Example/Example';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SYK from './pages/SYK/SYK';
import ExaPage from './pages/Example/ExaPage';
import AYH from './pages/AYH/AYHPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="" element={<HomePage />} />
        <Route path="/BodaKevinySusana" element={<SYK />} />
        <Route path="/BodaEjemplo" element={<ExaPage />} />
        <Route path="/BodaAlejandro&Hannia" element={<AYH />}/>
      </Routes>
    </Router>
  );
}

export default App;

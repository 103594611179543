import 'react-multi-carousel/lib/styles.css';
import React from 'react';
import styled from 'styled-components';
import Fondo from '../../assets/img/SYK/Fondo1.png'


const StyledFecha = styled.section`
@import url('https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&family=Gilda+Display&family=Great+Vibes&display=swap');

    font-family: "Gilda Display", serif;
    display: grid; /* Utilizamos CSS Grid para el diseño */
    grid-template-columns: 1fr auto 1fr; /* Creamos tres columnas iguales */
    align-items: center;
    padding: 20px;
    background-color: #919682;
    /* border: 2px solid #ccc; */
    border-radius: 5px;
    padding-top: 80px;
    background-image: url(${Fondo});
    background-size: cover; /* Hace que la imagen de fondo cubra todo el contenedor */
    background-position: center; /* Centra la imagen de fondo */
    
    @media (max-width: 768px) {
      padding: 150px 0 80px 0;
      background-size: contain; /* Ajusta la imagen para dispositivos medianos */
      background-position: center top; /* Ajusta la posición para dispositivos medianos */
    }
    
    @media (max-width: 480px) {
      padding: 100px 0 50px 0;
      background-size: contain; /* Ajusta la imagen para dispositivos pequeños */
      background-position: center top; /* Ajusta la posición para dispositivos pequeños */
    }
  
  .text-content3 {
    text-align: right;
    font-size: 18px;
    padding: 10px;
    grid-column: 1 / 2; /* El contenido de texto ocupa la primera columna */
    color: #fff;
  }
  
  .text-content4 {
    flex: 1;
    text-align: justify;
    font-size: 18px;
    padding: 10px;
    color: #fff;
  }
  
  .separator3 {
    grid-column: 2 / 3; /* El separador ocupa la segunda columna */
    display: flex; /* Permitimos que el separador crezca verticalmente */
    align-items: center; /* Centramos el separador verticalmente */
    height: 100%; /* Altura del separador */
    border-left: 2px solid #fff; /* Agregamos el estilo de separador vertical */
  }
  
  /* Media Queries para el diseño reactivo */
  @media screen and (max-width: 600px) {
    .content3 {
      flex-direction: column;
      align-items: stretch;
    }
    
    .separator3 {
      padding: 10px 0;
    }
  }
  
  .text-content4 a {
    color: #fff;
    text-decoration: none;
  }
`;

export const FechaSYK = () => {

    return (
    <StyledFecha id="lugar">
        <div className="text-content3">
            <h2>Agosto 29, 2024</h2>
            <h2>Recepción 5:00 p.m.</h2>
        </div>
        <div className="separator3"></div>
        <div className="text-content4">
            <h3><a href="https://maps.app.goo.gl/gZ9kocBPrnKx3r197" target="_blank">Jardín Ámbar, Reforma 1013, Centro, 64000</a></h3>
            <h3><a href="https://maps.app.goo.gl/gZ9kocBPrnKx3r197" target="_blank">Monterrey, N.L.</a></h3>
        </div>
    </StyledFecha>
      )
}
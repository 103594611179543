import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Fondo from '../../assets/img/SYK/Fondo2.png'

const StyledCountdown = styled.section`
@import url('https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&family=Gilda+Display&family=Great+Vibes&display=swap');
    display: flex;
    flex-wrap: wrap; /* Alinear los contenedores en una fila y envolver a la siguiente línea cuando no haya espacio */
    justify-content: center; /* Centrar horizontalmente */
    align-items: center; /* Centrar verticalmente */
    text-align: center; 
    font-family: "Gilda Display", serif;
    background-image: url(${Fondo});
background-size: cover; /* Hace que la imagen de fondo cubra todo el contenedor */
background-position: center; /* Centra la imagen de fondo */
background-color: #919682;

@media (max-width: 768px) {
  padding: 150px 0 80px 0;
  background-size: contain; /* Ajusta la imagen para dispositivos medianos */
  background-position: center top; /* Ajusta la posición para dispositivos medianos */
}

@media (max-width: 480px) {
  padding: 100px 0 50px 0;
  background-size: contain; /* Ajusta la imagen para dispositivos pequeños */
  background-position: center top; /* Ajusta la posición para dispositivos pequeños */
}
  
  .countdown-pair {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px;
  }
  
  .countdown-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFF0;
    /* border: 2px solid #005C46; */
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .countdown-value {
    font-size: 40px;
    /* font-weight: bold; */
    color: #13322C;
    font-family: Gilda;
  }
  
  .countdown-label {
    font-size: 24px;
    color: #FFFFF0;
    /* font-weight: bold; */
  }
  
  .countdown-title {
    font-size: 42px;
    /* font-weight: bold; */
    padding-top: 10px; 
    width: 100%; /* Asegurarse de que ocupe todo el ancho */
    color: #FFFFF0;
  }
`;

export const CountDownSYK = () => {
  const countdownDate = new Date("2024-08-29T17:00:00").getTime();

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const distance = countdownDate - now;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
<StyledCountdown>
  <div className="countdown-title">Faltan:</div>
      <div className="countdown-pair">
        <div className="countdown-label">Días</div>
        <div className="countdown-item">
          <div className="countdown-value">{timeLeft.days}</div>
        </div>
      </div>

      <div className="countdown-pair">
        <div className="countdown-label">Horas</div>
        <div className="countdown-item">
          <div className="countdown-value">{timeLeft.hours}</div>
        </div>
      </div>

      <div className="countdown-pair">
        <div className="countdown-label">Minutos</div>
        <div className="countdown-item">
          <div className="countdown-value">{timeLeft.minutes}</div>
        </div>
      </div>
    </StyledCountdown>
  );
}
import React from 'react';
import styled from 'styled-components';
import Fondo from '../../assets/img/SYK/Fondo2.png'

const StyledEndSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px 20px;
  background-image: url(${Fondo});
  background-size: cover; /* Hace que la imagen de fondo cubra todo el contenedor */
  background-position: center; /* Centra la imagen de fondo */
  background-color: #919682;
  
  @media (max-width: 768px) {
    padding: 150px 0 80px 0;
    background-size: contain; /* Ajusta la imagen para dispositivos medianos */
    background-position: center top; /* Ajusta la posición para dispositivos medianos */
  }
  
  @media (max-width: 480px) {
    padding: 100px 0 50px 0;
    background-size: contain; /* Ajusta la imagen para dispositivos pequeños */
    background-position: center top; /* Ajusta la posición para dispositivos pequeños */
  }

  .content-box {
    background-color: rgba(145, 150, 130, 0.8); /* Color semi-transparente */
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
  }

  .hashtag-section {
    font-size: 36px;
    font-weight: 300;
    color: #fff;
    margin-bottom: 30px;
    line-height: 1.6;
    font-family: 'Amiri', serif;
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon-camera {
      font-size: 48px;
      color: #fff;
      margin-bottom: 10px;
    }
  }

  .final-message {
    font-size: 48px;
    font-weight: 400;
    color: #fff;
    margin-top: 30px;
    font-family: "Great Vibes", cursive;
  }

  @media (max-width: 768px) {
    .hashtag-section {
      font-size: 16px;

      .icon-camera {
        font-size: 36px;
      }
    }

    .final-message {
      font-size: 20px;
    }

    .content-box {
        padding: 20px;
      }    
    padding: 0;
  }

  @media (max-width: 480px) {
    .hashtag-section {
      font-size: 14px;

      .icon-camera {
        font-size: 28px;
      }
    }

    .final-message {
      font-size: 18px;
    }
    .content-box {
        padding: 10px;
      }    
    
    padding: 0;

  }
`;

const DespedidaSYK = () => {
  return (
    <StyledEndSection>
      <div className="content-box">
        <div className="hashtag-section">
        <i class="fa-solid fa-camera-retro icon-camera"></i>
          <p>Usa el hashtag <strong>#Kevin&Susy</strong> para compartir todas tus fotos con nosotros en Facebook e Instagram</p>
        </div>
      </div>
      <div className="final-message">
          Será una bendición tenerte este día con nosotros.
        </div>
    </StyledEndSection>
  );
};

export default DespedidaSYK;

import { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import 'animate.css';
import styled, { keyframes } from 'styled-components';
import backgroundImage from '../../assets/img/EX/Foto7.jpeg'; // Importa la imagen de fondo

const updown = keyframes`
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
`;

const BannerSection = styled.section`
  @font-face {
    font-family: Centra;
    src: url('../../assets/font/CentraNo2-Bold.ttf');
    font-weight: 700;
  }
  @font-face {
    font-family: Centra;
    src: url('../../assets/font/CentraNo2-Medium.ttf') format("ttf");
    font-weight: 500;
  }
  @font-face {
    font-family: Centra;
    src: url('../../assets/font/CentraNo2-Book.ttf');
    font-weight: 400;
  }

  @font-face {
    font-family: RalewayIt;
    src: url('../../assets/font/Raleway-Italic-VariableFont_wght.ttf') format("ttf");
    font-weight: 400;
  }

  @font-face {
    font-family: Raleway;
    src: url('../../assets/font/Raleway-VariableFont_wght.ttf') format("ttf");
    font-weight: 400;
  }

  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url(${backgroundImage});
  background-image: linear-gradient(to bottom, rgba(255, 255, 240, 0.05), rgba(255, 255, 240, 0.1)), url(${backgroundImage});
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;

  .fontbig {
    font-size: 36px;
    vertical-align: middle;
    line-height: 25px;
  }

  .banner2 {
    margin-top: 0;
    padding: 60px 0 30px 0;
  }

  .banner .tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 20px;
    margin-bottom: 16px;
    display: inline-block;
  }

  .banner h1 {
    font-size: 180px;
    font-weight: 400;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
    font-family: Gistesy;
  }

  .banner p {
    color: #93B8AF;
    font-size: 36px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    font-family: Gilda;
  }

  .banner button {
    color: #93B8AF;
    font-weight: 700;
    font-size: 20px;
    margin-top: 60px;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;
  }

  .banner button svg {
    font-size: 25px;
    margin-left: 10px;
    transition: 0.3s ease-in-out;
    line-height: 1;
  }

  .banner button:hover svg {
    margin-left: 25px;
  }

  .banner img {
    animation: ${updown} 3s linear infinite;
  }

  .txt-rotate > .wrap {
    border-right: 0.08em solid #666;
  }
`;

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const toRotate = ["Grecia & Mateo"];
  const period = 1000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker); };
  }, [text, delta]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting 
      ? fullText.substring(0, text.length - 1) 
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(500);
    }
  };

  return (
    <BannerSection id="home">
      <Container>
        <Row className="align-items-center">
          <h1>{``} <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Grecia & Mateo" ]'><span className="wrap">{text}</span></span></h1>
          <p>24.12.2024</p>
        </Row>
      </Container>
    </BannerSection>
  );
};

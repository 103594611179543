import React from 'react';
import styled from 'styled-components';
import Fondo from '../../assets/img/SYK/Fondo2.png'

const StyledGiftSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px 20px;
  background-image: url(${Fondo});
  background-size: cover; /* Hace que la imagen de fondo cubra todo el contenedor */
  background-position: center; /* Centra la imagen de fondo */
  background-color: #919682;
  
  @media (max-width: 768px) {
    padding: 150px 0 80px 0;
    background-size: contain; /* Ajusta la imagen para dispositivos medianos */
    background-position: center top; /* Ajusta la posición para dispositivos medianos */
  }
  
  @media (max-width: 480px) {
    padding: 100px 0 50px 0;
    background-size: contain; /* Ajusta la imagen para dispositivos pequeños */
    background-position: center top; /* Ajusta la posición para dispositivos pequeños */
  }

  .content-box {
    background-color: rgba(145, 150, 130, 0.8); /* Color semi-transparente */
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
  }

  .title {
    font-size: 36px;
    font-weight: 400;
    color: #13322C;
    margin-bottom: 30px;
    font-family: 'Gilda Display', serif;
  }

  .description {
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    margin-bottom: 30px;
    line-height: 1.6;
    font-family: 'Amiri', serif;
  }

  .additional-info {
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    margin-bottom: 30px;
    line-height: 1.6;
    font-family: 'Amiri', serif;

    .icon-envelope {
      font-size: 56px; /* Tamaño del ícono del sobre */
      color: #fff;
      margin-top: 10px;
    }
  }

  .final-thanks {
    font-size: 24px;
    font-weight: 400;
    color: #13322C;
    margin-top: 30px;
    font-family: 'Gilda Display', serif;
  }

  @media (max-width: 768px) {
    .title {
      font-size: 28px;
    }

    .description, .additional-info {
      font-size: 16px;
    }

    .final-thanks {
      font-size: 20px;
    }

    .additional-info .icon-envelope {
      font-size: 36px; /* Tamaño del ícono del sobre para pantallas medianas */
    }
    .content-box {
      padding: 20px;
    }    
  
    padding: 0;
  }

  @media (max-width: 480px) {
    .title {
      font-size: 24px;
    }

    .description, .additional-info {
      font-size: 14px;
    }

    .final-thanks {
      font-size: 18px;
    }

    .additional-info .icon-envelope {
      font-size: 28px; /* Tamaño del ícono del sobre para pantallas pequeñas */
    }
    .content-box {
      padding: 10px;
    }    
    padding: 0;
  }
`;

const MesaRegaloSYK = () => {
  return (
    <StyledGiftSection>
<div className="content-box" id='mesa'>
        <div className="description">
          ¡Que nos acompañes es lo más importante! Y si está en tu corazón darnos un gesto de amor estaremos muy agradecidos.
        </div>
        <div className="title">Mesa de Regalos</div>
        <div className="additional-info">
          <strong>¡Lluvia de sobres!</strong> Consiste en regalar a los novios dinero en efectivo en un sobre el día del evento.
          <br /><br />
          <i class="fa-regular fa-envelope icon-envelope"></i>
          <br /><br />
          <strong>Si así lo prefieres, puedes realizar una transferencia</strong>.
          <br /><br />
          <strong>Kevin Santana Joachin</strong>
          <br /><br />
          <strong>Bancoppel</strong>
          <br /><br />
          <strong>4169161440043205</strong>
          <br /><br />
        </div>
        <div className="final-thanks">¡Gracias por tus muestras de cariño!</div>
      </div>
    </StyledGiftSection>
  );
};

export default MesaRegaloSYK;

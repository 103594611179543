import './HomePage.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "../../components/Home/NavBar";
import { Banner } from "../../components/Home/Banner";
import { Paquetes } from '../../components/Home/Paquetes';
import { Footer } from '../../components/Home/Footer';
import styled from 'styled-components';

const StyledMain = styled.section`
font-weight: 400;
overflow-x: hidden;
position: relative;
background-color: #84c6cc !important;
color: #fff !important;
font-family: Raleway

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-weight: 400;
    overflow-x: hidden;
    position: relative;
    background-color: #919682 !important;
    color: #fff !important;
    font-family: Corbel !important;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    line-height: normal;
  }
  
  p, a, li, button, ul {
    margin: 0;
    padding: 0;
    line-height: normal;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: none;
  }
  
  button {
    border: 0;
    background-color: transparent;
  }
  
  input:focus, textarea:focus, select:focus {
    outline: none;
  }
  
  @media (min-width:1700px) {
      main .container {
          max-width: 100%;
          padding: 0 150px;
      }
  }
  
`;

function HomePage() {
  return (
    <StyledMain className="App">
      <NavBar />
      <Banner />
      <Paquetes />
      <Footer />
    </StyledMain>
  );
}

export default HomePage;

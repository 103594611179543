import 'react-multi-carousel/lib/styles.css';
import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 20px;
  background-color: #FFFFF0;
  border-radius: 5px;
  font-family: Corbel !important;

  .text-content3 {
    text-align: right;
    font-size: 18px;
    padding: 10px;
    grid-column: 1 / 2;
    color: #005C46;
  }

  .text-content4 {
    text-align: justify;
    font-size: 18px;
    padding: 10px;
    padding-right: 55px;
    color: #005C46;
  }

  .separator3 {
    grid-column: 2 / 3;
    display: flex;
    align-items: center;
    height: 100%;
    border-left: 2px solid #005C46;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;
    
    .separator3 {
      padding: 10px 0;
    }
  }

  .text-content4 a {
    color: #005C46;
    text-decoration: none;
  }
`;

export const Fecha = () => {

  return (
    <Content id="lugar">
      <div className="text-content3">
        <h2>Quinta Eventos</h2>
        <h2>Civil 7:30</h2>
        <h2>Recepción 8:30</h2>
      </div>
      <div className="separator3"></div>
      <div className="text-content4">
        <h3><a href="https://maps.app.goo.gl/WyfE6Zi15YUfstGa8" target="_blank" rel="noopener noreferrer">Av. Gustave Eiffel, 75007 Paris, France</a></h3>
        <h3>Vestimenta formal con tenis blancos o negros</h3>
      </div>
    </Content>
  );
};

import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../../assets/img/Invit2.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import styled, { keyframes } from 'styled-components';

const updown = keyframes`
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
`;

const BannerSection = styled.section`
    @font-face {
        font-family: Centra;
        src: url('../../assets/font/CentraNo2-Bold.ttf');
        font-weight: 700;
    }
    @font-face {
        font-family: Centra;
        src: url('../../assets/font/CentraNo2-Medium.ttf') format ("ttf");
        font-weight: 500;
    }
    @font-face {
        font-family: Centra;
        src: url('../../assets/font/CentraNo2-Book.ttf');
        font-weight: 400;
    }

    @font-face {
        font-family: RalewayIt;
        src: url('../../assets/font/Raleway-Italic-VariableFont_wght.ttf') format ("ttf");
        font-weight: 400;
    }

    @font-face {
        font-family: Raleway;
        src: url('../../assets/font/Raleway-VariableFont_wght.ttf') format ("ttf");
        font-weight: 400;
    }

  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url('./assets/img/fondo.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    padding: 150px 0 80px 0;
  }

  @media (max-width: 480px) {
    padding: 100px 0 50px 0;
  }

  .tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    background: linear-gradient(90.21deg, rgba(144, 123, 1187, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 20px;
    margin-bottom: 16px;
    display: inline-block;

    @media (max-width: 768px) {
        font-size: 18px;
      }
    
      @media (max-width: 480px) {
        font-size: 16px;
      }
  }

  h1 {
    font-size: 75px;
    font-weight: 400;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: block;
    text-align: center;
    font-family: "RalewayIt";

    @media (max-width: 768px) {
        font-size: 50px;
      }
    
      @media (max-width: 480px) {
        font-size: 35px;
      }
  }

  p {
    color: #cac9c9;
    font-size: 28px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 96%;

    @media (max-width: 768px) {
        font-size: 24px;
      }
    
      @media (max-width: 480px) {
        font-size: 20px;
      }
  }

  button {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    margin-top: 60px;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;

    svg {
      font-size: 25px;
      margin-left: 10px;
      transition: 0.3s ease-in-out;
      line-height: 1;
    }

    &:hover svg {
      margin-left: 25px;
    }
  }

  img {
    animation: ${updown} 3s linear infinite;
    max-width: 100%;
  }

  .txt-rotate > .wrap {
    border-right: 0.08em solid #666;
  }
`;


export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Boda", "Despedida", "Cumpleaños" ];
  const period = 1000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text, delta])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting 
        ? fullText.substring(0, text.length - 1) 
        : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
    //   setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
    //   setIndex(1);
      setDelta(500);
    } 
    // else {
    //   setIndex(prevIndex => prevIndex + 1);
    // }
  }

  return (
    <BannerSection id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h1>{`Encuentra la invitación perfecta para tu `}
                    <span className="txt-rotate" dataPeriod="300" data-rotate='["Boda", "Despedida", "Cumpleaños"]'>
                      <span className="wrap">{text}</span>
                    </span>
                  </h1>
                </div>
              )}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={headerImg} alt="Header Img" />
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </BannerSection>
  )
}
import { BannerSYK } from '../../components/SYK/BannerSYK';
import { CountDownSYK } from '../../components/SYK/CountdownSYK';
import DespedidaSYK from '../../components/SYK/DespedidaSYK';
import { FechaSYK } from '../../components/SYK/FechaSYK';
import { MapSYK } from '../../components/SYK/MapSYK';
import MesaRegaloSYK from '../../components/SYK/MesaRegaloSYK';
import { NavBarSYK } from '../../components/SYK/NavBarSYK';
import PadresSYK from '../../components/SYK/PadresSYK';
import { VestimentaSYK } from '../../components/SYK/VestimentaSYK';
import './SYK.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function SYK() {
  return (
    <div className="App">
        <NavBarSYK />
        <BannerSYK />
        <CountDownSYK />
        <PadresSYK />
        <FechaSYK />
        <MapSYK />
        <VestimentaSYK />
        <MesaRegaloSYK />
        <DespedidaSYK />
    </div>
  );
}

export default SYK;

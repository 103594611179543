import Top from '../../components/AYH/Top';
import './AYHPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function AYH() {
  return (
    <div className="App">
        <Top/>
    </div>
  );
}

export default AYH;

import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../../assets/img/SYK/SYKLogo.png';
import styled from 'styled-components';

const StyledNavbar = styled(Navbar)`
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;

  &.scrolled {
    padding: 0px 0;
    background-color: #919682;
  }

  .navbar-brand {
    width: 9%;
  }

  .navbar-nav .nav-link {
    font-weight: 400;
    color: #fff !important;
    letter-spacing: 0.8px;
    padding: 0 25px;
    font-size: 16px;
    opacity: 0.75;

    &.active,
    &:hover {
      opacity: 1;
    }
  }

  .navbar-text {
    display: flex;
    align-items: center;

    .social-icon {
      display: inline-block;
      margin-left: 14px;
      color: white;

      a {
        width: 42px;
        height: 42px;
        background: rgba(217, 217, 217, 0.1);
        display: inline-flex;
        border-radius: 50%;
        margin-right: 6px;
        align-items: center;
        justify-content: center;
        line-height: 1;
        border: 1px solid rgba(255, 255, 255, 0.5);
        position: relative;

        &::before {
          content: "";
          width: 42px;
          height: 42px;
          position: absolute;
          background-color: #ffffff;
          border-radius: 50%;
          transform: scale(0);
          transition: 0.3s ease-in-out;
        }

        &:hover::before {
          transform: scale(1);
        }

        img {
          width: 40%;
          z-index: 1;
          transition: 0.3s ease-in-out;

          &:hover {
            filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
          }
        }
      }
    }

    button {
      font-weight: 700;
      color: #fff;
      border: 1px solid #fff;
      padding: 18px 34px;
      font-size: 16px;
      margin-left: 18px;
      position: relative;
      background-color: transparent;
      transition: 0.3s ease-in-out;

      span {
        z-index: 1;
      }

      &::before {
        content: "";
        width: 0%;
        height: 100%;
        position: absolute;
        background-color: #fff;
        left: 0;
        top: 0;
        z-index: -1;
        transition: 0.3s ease-in-out;
      }

      &:hover {
        color: #121212;

        &::before {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .navbar-toggler:active,
  .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
  }

  .navbar-toggler-icon {
    width: 24px;
    height: 17px;
    background-image: none;
    position: relative;
    border-bottom: 2px solid #fff;
    transition: all 300ms linear;
    top: -2px;

    &:after,
    &:before {
      width: 24px;
      position: absolute;
      height: 2px;
      background-color: #fff;
      top: 0;
      left: 0;
      content: '';
      z-index: 2;
      transition: all 300ms linear;
    }

    &:after {
      top: 8px;
    }
  }

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
    transform: rotate(45deg);
    background-color: #fff;
    height: 2px;
  }

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
    transform: translateY(8px) rotate(-45deg);
    background-color: #fff;
    height: 2px;
  }

  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    border-color: transparent;
  }
`;

export const NavBarSYK = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
      <StyledNavbar expand="md" className={`${scrolled ? "scrolled" : ""} navBarHome`}>
                <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Inicio</Nav.Link>
              <Nav.Link href="#lugar" className={activeLink === 'lugar' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('lugar')}>Lugar</Nav.Link>
              <Nav.Link href="#mapa" className={activeLink === 'mapa' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('mapa')}>Mapa</Nav.Link>
              <Nav.Link href="#mesa" className={activeLink === 'mesa' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('mesa')}>Mesa de Regalos</Nav.Link>
            </Nav>
            <span className="navbar-text">
            </span>
          </Navbar.Collapse>
        </Container>
      </StyledNavbar>
  )
}
import React from 'react';
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import styled from 'styled-components';
import { Foto } from "./Foto";
import projImg1 from "../../assets/img/EX/Foto1.jpeg";
import projImg2 from "../../assets/img/EX/Foto2.jpeg";
import projImg3 from "../../assets/img/EX/Foto3.jpeg";
import projImg5 from "../../assets/img/EX/Foto5.jpeg";
import projImg6 from "../../assets/img/EX/Foto6.jpeg";
import projImg8 from "../../assets/img/EX/Foto8.jpeg";

const StyledProjects = styled.section`
  padding: 50px 0;
  position: relative;
  background-color: #FFFFF0;

  h2 {
    font-size: 45px;
    font-weight: 400;
    text-align: center;
    color: #005C46;
  }

  p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px auto 30px auto;
    text-align: center;
    width: 56%;
  }

  .nav.nav-pills {
    width: 72%;
    margin: 0 auto;
    border-radius: 50px;
    background-color: rgb(255 255 255 / 10%);
    overflow: hidden;
  }

  .nav.nav-pills .nav-item {
    width: 33.33333%;
  }

  .nav.nav-pills .nav-link {
    background-color: transparent;
    border-radius: 0;
    padding: 17px 0;
    color: #fff;
    width: 100%;
    font-size: 17px;
    letter-spacing: 0.8px;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
    text-align: center;
    z-index: 0;

    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 100%;
      background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
      top: 0;
      left: 0;
      z-index: -1;
      transition: 0.3s ease-in-out;
    }

    &.active::before {
      width: 100% !important;
    }

    &.active {
      border: 1px solid rgba(255, 255, 255, 1);
    }

    &#projects-tabs-tab-first {
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 55px 0px 0px 55px;
    }

    &#projects-tabs-tab-second {
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }

    &#projects-tabs-tab-third {
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 0 55px 55px 0;
    }
  }

  .proj-imgbx {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;

    &::before {
      content: "";
      opacity: 0.85;
      position: absolute;
      width: 100%;
      height: 0;
      transition: 0.4s ease-in-out;
    }

    &:hover::before {
      height: 100%;
    }

    .proj-txtx {
      position: absolute;
      text-align: center;
      top: 65%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 0.5s ease-in-out;
      opacity: 0;
      width: 100%;

      h4 {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: 0.8px;
        line-height: 1.1em;
      }

      span {
        font-style: italic;
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 0.8px;
      }

      &:hover {
        top: 50%;
        opacity: 1;
      }
    }
  }

  .background-image-right {
    top: 20%;
    position: absolute;
    bottom: 0;
    width: 35%;
    right: 0;
    z-index: -4;
  }
`;

export const Fotos = () => {
  const projects = [
    { imgUrl: projImg1 },
    { imgUrl: projImg8 },
    { imgUrl: projImg3 },
    { imgUrl: projImg5 },
    { imgUrl: projImg6 },
    { imgUrl: projImg2 }
  ];

  return (
    <StyledProjects id="project">
      <Container>
        <Row>
          <Col size={12}>
            <h2>Galeria de fotos</h2>
            <p></p>
            <Tab.Container id="projects-tabs" defaultActiveKey="first">
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Row>
                    {projects.map((project, index) => (
                      <Foto key={index} {...project} />
                    ))}
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" />
    </StyledProjects>
  );
};

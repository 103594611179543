import 'react-multi-carousel/lib/styles.css';
import React from 'react';
import styled from 'styled-components';
import vestimentaImg from '../../assets/img/SYK/Etiqueta.png';
import Fondo from '../../assets/img/SYK/Fondo2.png'



const StyledMain = styled.section`
  @import url('https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&family=Gilda+Display&family=Great+Vibes&display=swap');
  font-family: 'Gilda Display', serif;
  padding-top: 70px;
  background-image: url(${Fondo});
  background-size: cover; /* Hace que la imagen de fondo cubra todo el contenedor */
  background-position: center; /* Centra la imagen de fondo */
  background-color: #919682;
  
  @media (max-width: 768px) {
    padding: 150px 0 80px 0;
    background-size: contain; /* Ajusta la imagen para dispositivos medianos */
    background-position: center top; /* Ajusta la posición para dispositivos medianos */
  }
  
  @media (max-width: 480px) {
    padding: 100px 0 50px 0;
    background-size: contain; /* Ajusta la imagen para dispositivos pequeños */
    background-position: center top; /* Ajusta la posición para dispositivos pequeños */
  }

  .tituloMesa {
    font-size: 45px;
    font-weight: 400;
    text-align: center;
    color: #fff;
  }

  .coctel {
    font-size: 45px;
    font-weight: 400;
    text-align: center;
    color: #fff;
    padding-top: 15px;
    font-family: 'Amiri', serif;
    font-weight: 400;
    font-style: italic;
  }

  @media (max-width: 768px) {
    padding-top: 30px;
    .tituloMesa {
      font-size: 26px;
    }

    .coctel {
      font-size: 26px;
    }
  }

  @media (max-width: 480px) {
    padding-top: 20px;
    .tituloMesa {
      font-size: 18px;
    }

    .coctel {
      font-size: 18px;
    }
  }
`;

const StyledVestimenta = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .circle {
    width: 50px; /* Tamaño mínimo de los círculos */
    height: 50px; /* Igual que el width para asegurar círculos */
    border-radius: 50%;
    margin: 0 10px; /* Espacio entre los círculos */
    background-color: red; /* Cambia el color de fondo para cada círculo */
  }

  @media (max-width: 768px) {
    .circle {
      width: 5vw; /* Ajusta el tamaño de los círculos para dispositivos medianos */
      height: 5vw; /* Igual que el width para asegurar círculos */
    }
  }

  @media (max-width: 480px) {
    .circle {
      width: 10vw; /* Ajusta el tamaño de los círculos para dispositivos pequeños */
      height: 10vw; /* Igual que el width para asegurar círculos */
    }
  }

  .colorVerde {
    background-color: #13322C;
  }
  .colorCafe {
    background-color: #994427;
  }
  .colorN {
    background-color: #B94816;
  }
  .colorA {
    background-color: #DA8B49;
  }
  .colorV {
    background-color: #D07E57;
  }

  .sugerencia-colores {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    color: #fff;
    font-family: 'Gilda Display', serif;
  }

  .detalles-colores {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
    font-family: 'Gilda Display', serif;

  }
`;

export const VestimentaSYK = () => {
  return (
    <StyledMain>
      <h1 className='tituloMesa'>Código de vestimenta</h1>
      <StyledVestimenta id="lugar">
        <div className="image-container">
          <img src={vestimentaImg} alt="Código de Vestimenta" />
        </div>
        <div className="sugerencia-colores">Sugerencia de colores</div>
        <div className="detalles-colores">Favor de no utilizar blanco</div>
        <div className="circle-container">
          <div className="circle colorVerde"></div>
          <div className="circle colorCafe"></div>
          <div className="circle colorN"></div>
          <div className="circle colorA"></div>
          <div className="circle colorV"></div>
        </div>
      </StyledVestimenta>
      <h1 className='coctel'>Coctel Formal</h1>
    </StyledMain>
  );
};
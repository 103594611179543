import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledCountDown = styled.div`
  .countdown-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .countdown-pair {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px;
  }

  .countdown-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFF0;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .countdown-value {
    font-size: 60px;
    color: #005C46;
    font-family: Gilda;
  }

  .countdown-label {
    font-size: 24px;
    color: #005C46;
  }

  .countdown-title {
    font-size: 42px;
    padding-top: 10px;
    width: 100%;
    color: #005C46;
  }
`;

export const CountDown = () => {
  const countdownDate = new Date("2024-12-24T18:30:00").getTime();

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const distance = countdownDate - now;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <StyledCountDown>
      <div className="countdown-container">
        <div className="countdown-title">Faltan:</div>
        <div className="countdown-pair">
          <div className="countdown-label">Días</div>
          <div className="countdown-item">
            <div className="countdown-value">{timeLeft.days}</div>
          </div>
        </div>
        <div className="countdown-pair">
          <div className="countdown-label">Horas</div>
          <div className="countdown-item">
            <div className="countdown-value">{timeLeft.hours}</div>
          </div>
        </div>
        <div className="countdown-pair">
          <div className="countdown-label">Minutos</div>
          <div className="countdown-item">
            <div className="countdown-value">{timeLeft.minutes}</div>
          </div>
        </div>
        <div className="countdown-pair">
          <div className="countdown-label">Segundos</div>
          <div className="countdown-item">
            <div className="countdown-value">{timeLeft.seconds}</div>
          </div>
        </div>
      </div>
    </StyledCountDown>
  );
};

import React from 'react';
import styled from 'styled-components';
import 'react-multi-carousel/lib/styles.css';

const Section = styled.section`
  font-family: Corbel !important;
  .skill-bx {
    text-align: center;
    margin-top: 30px;
    color: #93B8AF;
    width: 100%;
  }

  .skill h1 {
    font-size: 45px;
    font-weight: 700;
    color: #FFFFF0;
  }

  .skill p {
    color: #93B8AF;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
  }

  .background-image-left {
    top: 28%;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: -4;
  }
`;

export const Mensaje = () => {

  return (
    <Section id="fecha">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h1 className='textoGinely'>¡Queremos celebrar contigo!</h1>
              <p>
                <br />
                Este día tan significativo para nosotros no estaría completo sin la presencia y el amor de nuestra querida familia y amigos. Por ello, nos complace invitarlos a compartir este momento tan especial en nuestras vidas.
                Su apoyo y cariño han sido fundamentales para nuestro crecimiento como pareja y deseamos que estén a nuestro lado mientras comenzamos este nuevo capítulo.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

import React from 'react';
import { AiOutlineMail, AiOutlineGift, AiOutlineBank } from "react-icons/ai";
import Accordion from 'react-bootstrap/Accordion';
import styled from 'styled-components';

const StyledMesaRegalo = styled.section`
  background-color: #FFFFF0;
  padding: 50px 0;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  .col-12 {
    flex: 1;
    padding: 0 15px;
  }

  .skill-bx {
    text-align: center;
    background-color: #FFFFF0;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  .tituloMesa {
    font-size: 45px;
    font-weight: 400;
    color: #005C46;
    margin-bottom: 20px;
  }

  .textoDeMesa {
    text-align: center;
    padding: 10px 0;
    color: #005C46;
    font-family: Gilda;
  }

  .textoDeDatos {
    color: #005C46;
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: #005C46;
    margin-bottom: 20px;
  }

  .textoDeDatos h3 {
    color: #005C46;
  }
`;

export const MesaRegalo = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <StyledMesaRegalo id="mesa">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2 className='tituloMesa'>Mesa de regalos</h2>
              <div className='textoDeMesa'>
                <h3>El mejor regalo que nos puedes dar es tu presencia pero si quieres obsequiarnos algo
                  puedes hacerlo de esta forma:</h3>
                <div className='icon-container'>
                  <AiOutlineMail /> <AiOutlineGift />
                </div>
              </div>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h3 className='textoDeDatos'><AiOutlineBank /> Datos Bancarios</h3>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className='textoDeDatos'>
                      <h3># Tarjeta: 1324 5678 9100</h3>
                      <h3>Clabe: 0456 7890 4466 000000</h3>
                      <h3>BBVA John Doe</h3>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </StyledMesaRegalo>
  );
};

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../assets/img/EX/a&a.png";
import styled from 'styled-components';
import backgroundImage from '../../assets/img/EX/Foto6.jpeg';

const StyledFooter = styled.footer`
  padding: 0 0 50px 0;
  background-image: url('${backgroundImage}');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;

  img {
    width: 26%;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    color: #B8B8B8;
    letter-spacing: 0.5px;
    margin-top: 20px;
  }
`;

export const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
          </Col>
        </Row>
      </Container>
    </StyledFooter>
  );
};
